<template>
  <div>
    <div v-if="isEditable">
      <router-view />
    </div>
    <div v-else>
      <member-head
        :search="query.search"
        @handleRefresh="handleRefresh"
        @handleQuery="handleQuery"
        @handlAddVisible="handleAddVisible" />
      <member-table
        :tableData="pageData.data"
        :editable="editable"
        @handleRefresh="handleRefresh"
        @handleEditable="handleEditable"
        @handleEditData="handleEditData" />
      <member-pagination
        :query="query"
        :pageData="pageData"
        @handleQuery="handleQuery"
        @handleRefresh="handleRefresh" />
      <!-- Dialogs -->
      <!-- <add-dialog
        :visible="addVisible"
        @handleVisible="handleAddVisible"
        @handleRefresh="handleRefresh" />
      <edit-dialog
        :isUserCreated="isUserCreated"
        :visible="editVisible"
        :dialogData="editData"
        @handleVisible="handleEditVisible"
        @handleRefresh="handleRefresh" /> -->
    </div>
  </div>
</template>

<script>
import { forIn, has } from 'lodash'
import resStatus from '@/constants/resStatus'
import { getUserList } from '@/api/admin/member'// getUserStatus
// import { categoryType } from '@/constants/member'
import { adminLogout } from '@/utils/admin'

import MemberHead from './MemberHead'
import MemberTable from './MemberTable'
import MemberPagination from './MemberPagination'
// import AddDialog from './AddDialog'
// import EditDialog from './EditDialog'

export default {
  components: {
    MemberHead,
    MemberTable,
    MemberPagination
    // AddDialog,
    // EditDialog
  },
  data () {
    return {
      query: {
        page: 1,
        pageSize: 10,
        search: ''
      },
      editable: true,
      pageData: {
        data: []
      },
      // for add dialog
      addVisible: false,
      // for edit dialog
      isUserCreated: false,
      editVisible: false,
      editData: {},
      isEditable: false
    }
  },
  methods: {
    init () {
      const pageName = this.$route.name
      switch (pageName) {
        case 'AdminMemberAccountAdd':
        case 'AdminMemberAccountEdit':
          this.isEditable = true
          break
        default:
          this.isEditable = false
          getUserList(this.query)
            .then(res => {
              switch (res.status) {
                case resStatus.OK:
                  this.pageData = res
                  break
                default:
                  adminLogout()
                  this.$message.error(res.message)
              }
            })
            .catch(err => {
              adminLogout()
              this.$message.error(err)
            })
          break
      }
    },
    handleQuery (obj) {
      forIn(obj, (val, key) => {
        if (has(this.query, key)) {
          this.query[key] = val
        }
      })
    },
    handleEditable (boo) {
      this.editable = boo
    },
    handleAddVisible (boo) {
      this.addVisible = boo
    },
    handleEditVisible (boo) {
      this.editVisible = boo
    },
    handleEditData (data) {
      this.editData = data
      this.handleEditVisible(true)
    },
    handleRefresh () {
      // this.query.page = 1
      this.init()
    }
  },
  created () {
    this.init()
  },
  watch: {
    $route (to, from) {
      this.init()
    }
  }
}
</script>
