<template>
  <div class="search-container">
    <el-input
      v-model="searchInput"
      placeholder="请输入会员关键字"
      suffix-icon="el-icon-search" />
    <el-button
      type="primary"
      @click="handleRefresh">
      搜寻
    </el-button>
  </div>
</template>

<script>
export default {
  props: {
    search: {
      type: String,
      require: true
    }
  },
  computed: {
    searchInput: {
      set (str) {
        this.handleQuery({
          search: str
        })
      },
      get () {
        return this.search
      }
    }
  },
  methods: {
    handleQuery (obj) {
      this.$emit('handleQuery', obj)
    },
    handleRefresh () {
      this.handleQuery({
        page: 1
      })
      this.$emit('handleRefresh')
    }
  }
}
</script>

<style lang="scss" scoped>
.search-container {
  display: flex;
  align-items: center;
  gap: 15px;
}
</style>
