<template>
  <el-card
    shadow="never"
    :body-style="cardBodyStyle">
    <div class="left-container">
      <member-search
        :search="search"
        @handleQuery="handleQuery"
        @handleRefresh="handleRefresh" />
    </div>
    <div class="right-container">
      <el-button @click="handleAddMember">
        新增用户
      </el-button>
    </div>
  </el-card>
</template>

<script>
import MemberSearch from './MemberSearch'

export default {
  components: {
    MemberSearch
  },
  props: {
    search: {
      type: String,
      require: true
    }
  },
  computed: {
    cardBodyStyle: {
      get () {
        return {
          display: 'flex',
          'justify-content': 'space-between',
          'align-items': 'center'
        }
      }
    }
  },
  methods: {
    handleQuery (obj) {
      this.$emit('handleQuery', obj)
    },
    handleRefresh () {
      this.$emit('handleRefresh')
    },
    handleAddMember () {
      // this.$emit('handlAddVisible', true)
      this.$router.push({ name: 'AdminMemberAccountAdd' })
    }
  }
}
</script>
