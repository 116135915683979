<template>
  <el-table
    :data="tableData">
    <!-- 用户帐号 -->
    <el-table-column
      prop="username"
      label="用户帐号"
      min-width="200"
      align="center" />
    <!-- 类别 -->
    <el-table-column
      label="类别"
      min-width="100"
      align="center">
      <template slot-scope="scope">
        {{ getCategoryText(scope.row) }}
      </template>
    </el-table-column>
    <!-- 昵称 -->
    <el-table-column
      prop="nickname"
      label="昵称"
      min-width="200"
      align="center" />
    <!-- 等级 -->
    <el-table-column
      prop="level"
      label="等级"
      min-width="50"
      align="center" />
    <!-- 注册日期 -->
    <el-table-column
      label="注册日期"
      min-width="200"
      align="center">
      <template slot-scope="scope">
        {{ getCreatedDate(scope.row) }}
      </template>
    </el-table-column>
    <!-- 邮箱 -->
    <el-table-column
      prop="email"
      label="邮箱"
      min-width="200"
      align="center" />
    <!-- 手机 -->
    <el-table-column
      label="手机"
      min-width="200"
      align="center">
      <template slot-scope="scope">
        {{ scope.row.countrycode }} {{ scope.row.mobile }}
      </template>
    </el-table-column>
    <!-- QQ -->
    <el-table-column
      prop="qq"
      label="QQ"
      min-width="200"
      align="center" />
    <!-- 状态 -->
    <el-table-column
      label="状态"
      fixed="right"
      min-width="100"
      align="center">
      <template slot-scope="scope">
        <el-switch
          @change="handleChangeState($event, scope.row)"
          :disabled="!editable"
          v-model="scope.row.state"
          active-value="active"
          inactive-value="blocked" />
        {{ scope.row.state === "active" ? "激活" : "禁用" }}
      </template>
    </el-table-column>
    <!-- 操作 -->
    <el-table-column
      label="操作"
      fixed="right"
      min-width="100"
      align="center">
      <template slot-scope="scope">
        <el-tooltip
          effect="dark"
          content="修改"
          placement="top">
          <el-button
            :disabled="!editable"
            type="success"
            icon="el-icon-edit"
            @click="handleEdit(scope.row)" />
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { categoryType } from '@/constants/member'
import resStatus from '@/constants/resStatus'
import { getUserStatus } from '@/api/admin/member'
import { dateFormat } from '@/utils/time'

export default {
  props: {
    tableData: {
      type: Array,
      required: true
    },
    editable: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    getCategoryText (row) {
      return categoryType[row.category]
    },
    getCreatedDate (row) {
      return dateFormat('yyyy-MM-dd', row.created_at)
    },
    handleChangeState ($event, row) {
      this.handleEditable(false)
      getUserStatus(row.id, $event)
        .then(res => {
          switch (res.status) {
            case resStatus.OK:
              this.$emit('handleRefresh')
              break
          }
          if (res.message.length !== 0) {
            this.$message(res.message)
          }
          this.handleEditable(true)
        })
        .catch(err => {
          this.$message.error(err)
          this.handleEditable(true)
        })
    },
    handleEditable (boo) {
      this.$emit('handleEditable', boo)
    },
    handleEdit (row) {
      // this.$emit('handleEditData', row)
      this.$router.push({
        name: 'AdminMemberAccountEdit',
        params: {
          id: row.id
        }
      }).catch((err) => { console.log(err) })
    }
  }
}
</script>
